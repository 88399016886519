import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import redditpic from '../../../assets/buildapcsalesreddit.PNG';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function RecipeReviewCard() {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              titleTypographyProps={{variant:'h6'}}
              title="Reddit Crawler"
              subheader="December, 2019"
            />

            <CardMedia
                component="img"
                height="194"
                image={redditpic}
                alt="Paella dish"
            />

            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    When looking to build my first desktop computer during the crypto boom, I found it nearly impossible to buy the GPU I was looking for.
                    Using the Reddit API, I wrote a Python 3 script to look for keywords in every new post to the subreddit r/buildapcsales. When the script found
                    any relevant posts, it would email me a link to the post so that I could be one of the first to see it.
                    The script was run on a dedicated Raspberry Pi 3 B+ for maximum uptime. Within less than a week, it was responsible for getting me a source to the GPU I still use today.
                </Typography>
            </CardContent>

            <CardActions disableSpacing>
            
            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            Languages: Python 3
          </Typography>
          <Typography paragraph>
            Hardware: Raspberry Pi 3 B+
          </Typography>
          <Typography paragraph>
            IDE: PyCharm
          </Typography>
          <Typography>
            Other: Reddit API
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}