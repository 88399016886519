import {Component} from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';

import AboutMe from './Tabs/AboutMe';
import CryptoTicker from './Tabs/Projects/CryptoTicker';
import DDRTOS from './Tabs/Projects/DDRTOS';
import DiscordRemote from './Tabs/Projects/DiscordRemote';
import RealEstate from './Tabs/Projects/RealEstate';
import RedditBot from './Tabs/Projects/RedditBot';
import Resume from './Tabs/Resume';
import Testla from './Tabs/Projects/Testla';
import VectorGraphics from './Tabs/Projects/VectorGraphics';
import WirelessEV from './Tabs/Projects/WirelessEV';

import ThisPortfolio from './Tabs/Projects/ThisPortfolio';
import './SectionTabs.css';

class SectionTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "1"
        }
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    render() {
        return (
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={this.handleChange} aria-label="lab API tabs example" centered>
                            <Tab label="About Me" value="1" />
                            <Tab label="Resume" value="2" />
                            <Tab label="Projects" value="3" />
                        </TabList>
                    </Box>

                    <TabPanel value="1">
                        <AboutMe/>
                    </TabPanel>

                    <TabPanel value="2">
                        <Resume />
                    </TabPanel>
                    
                    <TabPanel value="3">
                        <Container>
                            <Grid container spacing={1} direction="row" justifyContent="center" alignItems="flex-start">
                                <Grid item>
                                    <Testla />
                                </Grid>
                                <Grid item>
                                    <DDRTOS />
                                </Grid>
                                <Grid item>
                                    <VectorGraphics />
                                </Grid>
                                <Grid item>
                                    <CryptoTicker />
                                </Grid>
                                <Grid item>
                                    <WirelessEV />
                                </Grid>
                                <Grid item>
                                    <RealEstate />
                                </Grid>
                                <Grid item>
                                    <DiscordRemote />
                                </Grid>
                                <Grid item>
                                    <RedditBot />
                                </Grid>
                                <Grid item>
                                    <ThisPortfolio />
                                </Grid>
                            </Grid>
                        </Container>
                    </TabPanel>
                </TabContext>
          </Box>
        );
    }
}

export default SectionTabs;