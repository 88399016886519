import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Website from '../../../assets/Website.PNG';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function RecipeReviewCard() {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              titleTypographyProps={{variant:'h6'}}
              title="This Portfolio"
              subheader="January, 2022"
            />

            <CardMedia
                component="img"
                height="194"
                image={Website}
                alt="A screenshot of this website"
            />

            <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Created the very portfolio you're looking at using React and the Material-UI library.
                  Firebase used for website hosting and resume storage.
                </Typography>
            </CardContent>

            <CardActions disableSpacing>
            
            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        <Typography paragraph>
            Language: JavaScript
          </Typography>
          <Typography paragraph>
            Libraries: React, Material-UI
          </Typography>
          <Typography paragraph>
            IDE: Visual Studio Code
          </Typography>
          <Typography>
            Other: Firebase Hosting, Firebase Storage
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}