import { getStorage, ref, getDownloadURL} from "firebase/storage";

import { Typography } from '@material-ui/core';

import resumePreview from '../../assets/Mike Hatzi - resume.jpg'
import '../SectionTabs.css'

const downloadResume = (gsReference) => {
    getDownloadURL(gsReference)
        .then((url) => {
            console.log(url)
        window.location.href = url
    })
    .catch((error) => {
        // Handle any errors
    });
}

function Resume() {

    const storage = getStorage();
    const gsReference = ref(storage, 'gs://portfolio-4c9cb.appspot.com/Mike Hatzi - Computer Engineering Resume.pdf');

    return (
        <>
            <div className="downloadText">
                <Typography  variant="overline" align="center">
                    Click image to download
                </Typography>
            </div>

            <div className="resume">
                <img onClick={() => downloadResume(gsReference)} width="300" src={resumePreview}/>
            </div>
        </>
    );
}

export default Resume;