import { CssBaseline, Container } from '@material-ui/core';

import TopHalf from './components/TopHalf/TopHalf';
import SectionTabs from './components/SectionTabs/SectionTabs';

import './App.css';

function App() {
  return (
    <>
      <CssBaseline />
      <main>
        <div className="TopBackground">
          <TopHalf/>
        </div>

        <SectionTabs />
      </main>
    </>
  );
}

export default App;
