import React, {Component} from 'react'

import { Typography, Container } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import ReactPlayer from 'react-player';

import './AboutMe.css'

import GradMike from '../../assets/Graduation.JPG';
import NevroMike from '../../assets/Nevro.JPG';

class AboutMe extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Container maxWidth="lg">
                <div className="textHeader">
                    <Typography variant="h5" gutterBottom>
                        Bio
                    </Typography>
                </div>

                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12} lg={8}>
                        <Typography variant="h6" align="right" paragraph>
                            I'm currently obtaining my Master's in Computer Engineering at SJSU, where I've also received my Bachelor's in Electrical Engineering. 
                            I'm deeply passionate about music, virtual reality, and anything automation.
                            My undergraduate education enabled me to discover that working purely in hardware was not satisfying enough to me. Studying computer engineering and, specifically,
                            embedded systems has allowed me to take on problem-solving challenges where I can push myself the most.
                            I'm searching for internship opportunities for Summer 2022, as well as full-time employment after I graduate at the end of 2022.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                    <   div align="center">
                            <img width="300" src={GradMike} alt="SJSU Graduation"/>
                        </div>
                    </Grid>
                </Grid>

                <hr className="solid"></hr>

                <div className="textHeader">
                    <Typography variant="h5" gutterBottom>
                        Experience
                    </Typography>
                </div>

                <Grid container spacing={1} direction="row-reverse" justifyContent="center" alignItems="center">
                    <Grid item xs={12} lg={8}>
                        <Typography variant="h6" align="left" paragraph>
                            I currently work at Murphy, McKay, & Associates in Lafayette, CA as a Full Stack Engineer developing the company's internal applications.
                            This includes both scheduling software and real-time project analysis tools. My time here has exposed me to multiple technologies, including
                            JavaScript, React, and Firebase.
                        </Typography>

                        <Typography variant="h6" align="left" paragraph>
                            Prior to my full-stack position, I worked as an R&D Engineering Intern at Nevro in Redwood City, CA. I developed Python scripts
                            as part of a test system overhaul my team was engaged in. In a short time, this role allowed me to hone my skills in Python and gain my first experience with
                            database interaction through Amazon Web Services.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                    <   div align="center">
                            <img width="300" src={NevroMike} alt="SJSU Graduation"/>
                        </div>
                    </Grid>
                </Grid>

                <hr className="solid"></hr>

                <div className="textHeader">
                    <Typography variant="h5" gutterBottom>
                        Hobbies
                    </Typography>
                </div>

                <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" paragraph>
                            Around 2018, I started diving into electronic music for the first time in years. At the start of the following year, I bought a Pioneer DDJ-SB2 dj controller
                            from a friend and began experimenting with mixing my music library and making mashups. Through the rest of the year I released a couple of mixes for fun and collaborated
                            with a good friend on the second iteration of his mix series:
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <ReactPlayer
                            height="100%"
                            width="100%"
                            url='https://soundcloud.com/ayojoemoe/recovery-mix-vol-2-ft-mike-hatzi'
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" paragraph>
                            In March of 2020, I worked with friends to put together Equinox, a music livestream event presented on Twitch for charity.
                            We raised over $1100 to support Stop AAPI Hate by the end of the stream. This was my first production of a set involving a visual element added with 
                            Adobe Premiere Pro and After Effects.
                        </Typography>
                    </Grid>

                    <Grid align="center" item xs={12} md={6} lg={6}>
                        <ReactPlayer
                            playing={false}
                            height="100%"
                            width="100%"
                            controls={true}
                            url="https://firebasestorage.googleapis.com/v0/b/portfolio-4c9cb.appspot.com/o/EquinoxPortfolio.mp4?alt=media&token=b7e2e64b-efd9-4b87-8beb-8f2b903d3b08"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" paragraph>
                            While I've been a big fan of videogames most of my life, I've been blown away by the progress of virtual reality games and applications.
                            I've spent a couple hundred hours in VR playing games like Boneworks, Beat Saber, Pavlov, Phasmophobia, Windlands 2, and VRChat. 
                            Both the software and hardware behind this technology fascinates me. It's a personal goal to be a part of the technological advancement of VR systems.
                        </Typography>
                    </Grid>

                    <Grid align="center" item xs={12} md={6} lg={6}>
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            playing={true}
                            volume={0}
                            loop={true}
                            url="https://firebasestorage.googleapis.com/v0/b/portfolio-4c9cb.appspot.com/o/Boneworks.mp4?alt=media&token=1a9fc5ac-d907-4039-be8d-281c90575e51"
                        />
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

export default AboutMe;


//https://firebasestorage.googleapis.com/v0/b/portfolio-4c9cb.appspot.com/o/ApexClutch.mp4?alt=media&token=5b9c6e00-4ac5-4304-8ab7-de77c3dcb0cc
//https://firebasestorage.googleapis.com/v0/b/portfolio-4c9cb.appspot.com/o/PhasClip.mp4?alt=media&token=b9c4f6d3-b976-42de-a9e5-8d78c7d72296
//https://firebasestorage.googleapis.com/v0/b/portfolio-4c9cb.appspot.com/o/Teamwork.mp4?alt=media&token=da5d11fd-6dd8-4fb1-8ee8-c3073fd0a779
//https://firebasestorage.googleapis.com/v0/b/portfolio-4c9cb.appspot.com/o/Whats%20a%20V1.mp4?alt=media&token=53a140aa-024e-426f-bdb6-b759d5c443e6
