import { Component } from 'react';

import jmike from '../assets/Japan-Mike3.png'
import { Container } from '@material-ui/core';
import Typography from "@mui/material/Typography";

import IconBar from './IconBar/IconBar'
import './TopHalf.css'

class TopHalf extends Component {
    constructor (props) {
        super(props);
        this.state = {

        }
    }

    render() {  
        return(
            <Container maxWidth="sm">
                <div align="center">
                    <img className="ProfilePic" width="300" src={jmike} alt="profile picture"/>
                </div>

                <Typography align="center" variant="h3" color="common.white">
                    Michael Hatzikokolakis
                </Typography>

                <Typography align="center" variant="h6" color="common.white">
                    Computer Engineering (Embedded Systems Focus)
                </Typography>

                <IconBar />
            </Container>
        )
    }
}

export default TopHalf;