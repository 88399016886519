import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CryptoTicker from '../../../assets/CryptoTicker.JPG';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function RecipeReviewCard() {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              titleTypographyProps={{variant:'h6'}}
              title="Cryptocurrency Ticker"
              subheader="May, 2020"
            />

            <CardMedia
                component="img"
                height="194"
                image={CryptoTicker}
                alt="Crypto Ticker"
            />

            <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Implemented a currency ticker app using Python 3.6 and the GDAX library to invoke REST API calls to extract cuurency data from a public exchange server.
                  Data was sent from a Raspberry Pi on Raspbian OS through an I2C connection to an Arduino Uno.
                  Currency data was then projected to a handmade LED matrix.
                </Typography>
            </CardContent>

            <CardActions disableSpacing>
            
            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            Languages: Python 3, C
          </Typography>
          <Typography paragraph>
            Hardware: Raspberry Pi 3 B+, Arduino Uno, WS2812 LEDs
          </Typography>
          <Typography paragraph>
            IDE: PyCharm, Arduino Uno
          </Typography>
          <Typography>
            Other: I2C, GDAX API
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}