import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DDRTOS from '../../../assets/DDRTOS.png';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function RecipeReviewCard() {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              titleTypographyProps={{variant:'h6'}}
              title="DDRTOS"
              subheader="December, 2022"
            />

            <CardMedia
                component="img"
                height="194"
                image={DDRTOS}
                alt="DDRTOS"
            />

            <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Utilized an SJ2 microcontroller running FreeRTOS to code an arcade-style rhythm game in a team of three.
                  A 3D printed enclosure, a wired controller, and custom designed PCBs were included to make the console
                  presentable and portable.
                </Typography>
            </CardContent>

            <CardActions disableSpacing>
            
            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            Languages: C
          </Typography>
          <Typography paragraph>
            Hardware: SJ2
          </Typography>
          <Typography paragraph>
            IDE: Visual Studio
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};