import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// Set the configuration for your app
// TODO: Replace with your app's config object
const firebaseConfig = {
  apiKey: "AIzaSyDSTENGCnKQ10ICHPfigIunKJCctXKSRI0",
  authDomain: "portfolio-4c9cb.firebaseapp.com",
  projectId: "portfolio-4c9cb",
  storageBucket: "portfolio-4c9cb.appspot.com",
  messagingSenderId: "808200999319",
  appId: "1:808200999319:web:e887faf19720f34bc834d2",
  measurementId: "G-KQT1WE9BKE"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
