import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import WirelessCharging from '../../../assets/WirelessCharging.JPG';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function RecipeReviewCard() {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              titleTypographyProps={{variant:'h6'}}
              title="Wireless EV Charging System"
              subheader="December, 2020"
            />
            <CardMedia
                component="img"
                height="194"
                image={WirelessCharging}
                alt="Paella dish"
            />

            <CardContent>
                <Typography variant="body2" color="text.secondary">
                  My electrical engineering education culminated in a senior project with three other teammates that leaned heavily into power systems management.
                  I focused my contributions on a PID controller for cleaning power input signals to deliver to the main system. 
                  I used a Texas Instruments F28335 microcontroller coded in C for this purpose and was able to complete this subsystem by the project end date.
                </Typography>
            </CardContent>

            <CardActions disableSpacing>
            
            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            Language: C
          </Typography>
          <Typography paragraph>
            Hardware: TI F28335 with C2000 peripheral explorer kit
          </Typography>
          <Typography paragraph>
            IDE: TI Code Composer Studio
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}