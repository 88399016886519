/////////////////////////
//                     //
//  IMPLEMENT ICONBAR  //
//                     //
/////////////////////////
import react, {Component} from 'react'

import { Container } from '@material-ui/core';
import { Icon } from '@iconify/react';

import IconBarFunction from './IconBarFunction'
import './IconBar.css'

class IconBar extends Component {
    constructor (props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Container align="center">
                <a className="LinkColor" href="https://www.linkedin.com/in/michael-hatzikokolakis/">
                    <Icon className="Icon" icon="akar-icons:linkedin-fill" width="50" />      
                </a>

                <a className="LinkColor" href="https://github.com/mikehatzi">
                    <Icon className="Icon" icon="akar-icons:github-fill" width="50" />
                </a>

                <a className="LinkColor" href="https://www.instagram.com/hatzicocacola/">
                    <Icon className="Icon" icon="akar-icons:instagram-fill" width="50" />
                </a>

                <a className="LinkColor" href="https://soundcloud.com/michael-hatzikokolakis">
                        <Icon className="Icon" icon="mdi:soundcloud" width="50" />
                </a>
            </Container>
        )
    }
}

export default IconBar;