import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import VectorGraphics from '../../../assets/3DVectorGraphics.jpg';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function RecipeReviewCard() {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
              titleTypographyProps={{variant:'h6'}}
              title="Vector Graphics Design"
              subheader="December, 2021"
            />

            <CardMedia
                component="img"
                height="194"
                image={VectorGraphics}
                alt="Vector Graphics"
            />

            <CardContent>
                <Typography variant="body2" color="text.secondary">
                  Designed and drew 3D graphics to a TFT LCD screen powered by an LPC 1769 processor.
                  A 3D to 2D transformation pipeline was utilized to enable custom and configurable outputs.
                  Physical system was contained to a portable, battery-powered wire wrapping board.
                </Typography>
            </CardContent>

            <CardActions disableSpacing>

            <ExpandMore
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            Language: C
          </Typography>
          <Typography paragraph>
            Hardware: LPC 1769, ST7735 TFT LCD Display
          </Typography>
          <Typography paragraph>
            IDE/Debugger: MCUXpresso
          </Typography>
          <Typography>
            Other: SPI
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}